import React from 'react';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import serialize from 'serialize-javascript';
import MobileApi from 'lib/MobileApi';

function AnalyticsLaunchScripts(props) {
  const { isChromeless = false, pageID = '' } = props;
  const {
    publicRuntimeConfig: {
      dev,
    },
  } = getConfig();

  const launchScripts = {
    prod: '//assets.adobedtm.com/launch-EN03682f66064449c8b87d78eae3e2fc57.min.js',
    stage: '//assets.adobedtm.com/launch-EN73ef4bfbb64046d89ea9b8f64998688c-staging.min.js',
  };

  if (isChromeless && pageID) {
    const js = `window.__MobileApi = ${serialize(
      MobileApi,
    )}; __MobileApi.trackPageView('${pageID}');`;
    return <script dangerouslySetInnerHTML={{ __html: js }} />;
  }

  const launchSrc = dev ? launchScripts.stage : launchScripts.prod;

  return (
    <script src={launchSrc} />
  );
}

AnalyticsLaunchScripts.propTypes = {
  isChromeless: PropTypes.bool,
  pageID: PropTypes.string,
};

export { AnalyticsLaunchScripts };
